import React, { Component, useState } from "react";
import { RiMenuLine, RiLayoutGridFill, RiRulerLine, RiSettings5Line } from 'react-icons/ri'
import { HiOutlineViewList } from 'react-icons/hi'
import { AiOutlineDoubleRight, AiOutlineDoubleLeft } from 'react-icons/ai'
import { MdRule, MdOutlineLogout, MdOutlineLogin, MdHelp, MdWarning } from 'react-icons/md'
import { GrHistory } from 'react-icons/gr'
import { Link } from "react-router-dom";
import './Sidebar.css'

import logo from '../images/logo-columbus-white.svg'

import AuthService from "../services/auth.service";
import * as constants from '../common/constants'


let isExpanded = true;

class Sidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentUser: undefined,
        };
    }
    setIsExpanded(open) {
        this.setState({ sidebarCollapsed: open });
        isExpanded = open;
    }



    componentDidMount() {
        const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
        this.setIsExpanded(sidebarCollapsed ? false : true);
    }

    render() {

        const logOut = () => {
            AuthService.logout();

            window.location.reload();
        }


        const handleToggler = () => {
            if (isExpanded) {
                this.setIsExpanded(false);
                localStorage.setItem('sidebar-collapsed', true);
                return;
            }
            this.setIsExpanded(true);
            localStorage.removeItem('sidebar-collapsed');
        };

        return (

            <nav className="sidebar">
                <div className={isExpanded ? "Sidebar" : "Sidebar collapsed"}>
                    <div className="sidebar-header">
                        <AiOutlineDoubleRight className="sidebar-icon-arrowRight" onClick={handleToggler} />
                        <AiOutlineDoubleLeft className="sidebar-icon-arrowLeft" onClick={handleToggler} />
                        <img className="sidebar-logo" src={logo} />
                    </div>
                    <div className="sidebar-items">
                        <Link to={"/home"} >
                            <div className="item">
                                <RiLayoutGridFill className="sidebar-icon" />
                                <span className="sidebar-text">Home</span>
                            </div>
                        </Link>
                        <Link to={"/restructuring"}>
                            <div className="item">
                                <HiOutlineViewList className="sidebar-icon" />
                                <span className="sidebar-text">Restructuring</span>
                            </div>
                        </Link>
                        <Link to={"/rules"}>
                            <div className="item">
                                <RiRulerLine className="sidebar-icon" />
                                <span className="sidebar-text">Rules</span>
                            </div>
                        </Link>
                        <Link to={"/selCrit"}>
                            <div className="item">
                                <MdRule className="sidebar-icon" />
                                <span className="sidebar-text">Selection criteria</span>
                            </div>
                        </Link>
                        {AuthService.getAppSetting(constants.SETTING_SHOWXREFLOGS)?.value === "true" ? 
                            <Link to={"/xrefLogs"}>
                                <div className="item">
                                    <GrHistory className="sidebar-icon" />
                                    <span className="sidebar-text">X-Ref logs</span>
                                </div>
                            </Link>
                            : ""}
                        {AuthService.getAppSetting(constants.SETTING_SHOWM3UPLOADLOGS)?.value === "true" ? 
                            <Link to={"/m3uploadLogs"}>
                                <div className="item">
                                    <GrHistory className="sidebar-icon" />
                                    <span className="sidebar-text">M3 Upload logs</span>
                                </div>
                            </Link>
                            : ""}
                        {AuthService.hasPermission(constants.PERMISSION_MAINTENANCE) ?
                            <Link to={"/settings"}>
                                <div className="item">
                                    <RiSettings5Line className="sidebar-icon" />
                                    <span className="sidebar-text">Settings</span>
                                </div>
                            </Link>
                            : ""}
                        <br />
                        <br />
                        <br />

                        {AuthService.getAppSetting(constants.SETTING_LOCKDOWN)?.value === "true" ? 
                            <div className="item">
                                <MdWarning className="sidebar-icon sidebar-warning" />
                                <span className="sidebar-text sidebar-warning">System locked down.</span>
                            </div> : ""}


                        <Link to={"/documentation"}>
                            <div className="item">
                                <MdHelp className="sidebar-icon" />
                                <span className="sidebar-text">Documentation</span>
                            </div>
                        </Link>
                        {!AuthService.isLoggedIn() ?
                            <Link to={"/login"} className="bottom">
                                <div className="item">
                                    <MdOutlineLogin className="sidebar-icon" />
                                    <span className="sidebar-text">Login</span>
                                </div>
                            </Link>
                            : ""}
                        {AuthService.isLoggedIn() ?
                            <Link to={"/"} className="bottom" onClick={() => { logOut(); }}>
                                <div className="item">
                                    <MdOutlineLogout className="sidebar-icon" />
                                    <span className="sidebar-text">Logout</span>
                                </div>
                            </Link>
                            : ""}
                    </div>

                </div>
            </nav>
        )
    }

}

export default Sidebar;
import React from "react";
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
import * as constants from '../common/constants'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useSnackbar } from 'notistack';

import { v4 as uuidv4 } from 'uuid';

import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
} from '@mui/x-data-grid';

import { triggerBase64Download } from "../common/base64downloader";

function RuleMNDialog(props) {

    //update rowsMN when props.rowsMN changes !!!
    React.useEffect(() => {

        clearDialog();
        if (props.rowToEdit !== null) {
            loadRuleData();
        }

    }, [props.rowToEdit]);

    const [rowsMN, setRowsMN] = React.useState([]);
    const [columnsDymamic, setColumnsDymamic] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [openDialogUpload, setOpenDialogUpload] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState(undefined);
    const [replaceData, setReplaceData] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const loadRuleData = () => {
        const tableMeta = JSON.parse(props.rowToEdit.tableMeta);
        UserService.getRuleData(props.rowToEdit.id).then(response => {
            let tmpData = response.data.jsonData;
            let rulesMnData = tmpData.map(obj => ({ ...obj, id: uuidv4() }));
            setRowsMN(rulesMnData);

            const tmpColumns = [];
            tableMeta.columns.map((obj) => {
                let tmpCol = {
                    field: obj.name,
                    headerName: obj.name,
                    width: 130,
                    editable: true,
                    type: obj.dataType === 'DECIMAL' ? 'number' : '',
                };
                tmpColumns.push(tmpCol);
                return "";
            });

            const stsCol = {
                field: 'STS',
                headerName: 'STS',
                width: 50,
                editable: true,
            }
            tmpColumns.push(stsCol);

            if (tableMeta.hasManColumn) {
                const manCol = {
                    field: 'MAN',
                    headerName: 'MAN',
                    width: 50,
                    editable: true,
                }
                tmpColumns.push(manCol);
            }

            if (tableMeta.hasDupColumn) {
                const dupCol = {
                    field: 'DUP',
                    headerName: 'DUP',
                    width: 50,
                    editable: true,
                }
                tmpColumns.push(dupCol);
            }

            setColumnsDymamic(tmpColumns);

        });
    }

    const clearDialog = () => {
        setRowsMN([]);
        setColumnsDymamic([]);
        setRowModesModel({});
    }

    const handleCloseEditMN = (reload) => {
        props.onClose();

    };

    const handleCloseSaveMN = (reload) => {
        updateMN();
    };

    const updateMN = () => {
        UserService.updateRuleData(props.rowToEdit.id, { "jsonData": rowsMN, "replaceData": true }).then(
            response => {
                enqueueSnackbar('Rule ' + props.rowToEdit.name + ' has been updated', { variant: 'success' });
                clearDialog();
                props.onClose();
            },
            error => {
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    }

    const handleCloseUpload = () => {
        setSelectedFile(undefined);
        setReplaceData(false);
        setOpenDialogUpload(false);
    }

    const handleSaveUpload = () => {
        let base64 = "";
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(selectedFile);

        // on reader load somthing...
        reader.onload = () => {

            base64 = reader.result;
            let data = {
                "fileData": base64.substring(base64.search("base64,") + 7), //to send data only
                "replaceData": replaceData
            }
            UserService.uploadRuleData(props.rowToEdit.id, data).then(res => {
                enqueueSnackbar('Data for rule ' + props.rowToEdit.name + ' successfully uploaded.', { variant: 'success' });
                setSelectedFile(undefined);
                setReplaceData(false);
                setOpenDialogUpload(false);
                loadRuleData();
            },
                error => {
                    const resMessage =
                        (error.response.data.errorMessage) ||
                        error.message ||
                        error.toString();
                    enqueueSnackbar(resMessage.substring(0,500), { variant: 'error' });
                });
        };
    }

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {
        setRowsMN(rowsMN.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rowsMN.find((row) => row.id === id);
        if (editedRow != null && editedRow.isNew) {
            setRowsMN(rowsMN.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRowsMN(rowsMN.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const EditToolbar = (propsEdit) => {
        const handleClick = () => {

            const id = uuidv4();
            let tmpRows = [];

            tmpRows = [
                ...rowsMN
            ];

            let newRow = { id: id };
            columnsDymamic.map((col) => {
                newRow[col.field] = '';
                return "";
            })

            tmpRows.push(newRow);
            setRowsMN(tmpRows);

        };

        const handleClickDownload = () => {

            UserService.downloadRuleData(props.rowToEdit.id).then(
                response => {
                    if (response.data.ruleDataBase64) {
                        triggerBase64Download('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
                            + response.data.ruleDataBase64, 'rule_' + props.rowToEdit.name);
                    }
                },
                error => {
                    const resMessage =
                        (error.response.data.errorMessage) ||
                        error.message ||
                        error.toString();
                    enqueueSnackbar(resMessage, { variant: 'error' });
                }
            );

        }

        const handleClickUpload = () => {
            setOpenDialogUpload(true);
        }

        return (
            <div>
                <GridToolbarContainer>
                    {AuthService.hasPermission(constants.PERMISSION_EDIT) ?
                        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                            Add new row
                        </Button>
                        : ""}
                    <Button color="primary" startIcon={<DownloadIcon />} onClick={handleClickDownload}>
                        Download data
                    </Button>
                    {AuthService.hasPermission(constants.PERMISSION_EDIT) ?
                        <Button color="primary" startIcon={<UploadIcon />} onClick={handleClickUpload}>
                            Upload Excel
                        </Button>
                        : ""}

                </GridToolbarContainer>
            </div>
        );
    }

    const actionsCol = {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
                return [
                    <GridActionsCellItem
                        icon={<SaveIcon />}
                        label="Save"
                        onClick={handleSaveClick(id)}
                    />,
                    <GridActionsCellItem
                        icon={<CancelIcon />}
                        label="Cancel"
                        className="textPrimary"
                        onClick={handleCancelClick(id)}
                        color="inherit"
                    />,
                ];
            }

            return [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    className="textPrimary"
                    onClick={handleEditClick(id)}
                    color="inherit"
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleDeleteClick(id)}
                    color="inherit"
                />,
            ];
        },
    }

    const handleFileInputChange = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    return (
        <div>
            <Dialog
                open={props.openDialogEdit}
                onClose={handleCloseEditMN}
                fullWidth={true}
                maxWidth="md"
                disableEscapeKeyDown
            >
                <DialogTitle>Edit M:N data ({props.rowToEdit?.name})</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>

                    <Box
                        sx={{
                            height: 500,
                            width: '100%',
                            '& .actions': {
                                color: 'text.secondary',
                            },
                            '& .textPrimary': {
                                color: 'text.primary',
                            },
                        }}
                    >
                        <DataGrid
                            sx={{
                                '.MuiDataGrid-row--editing': { bgcolor: '#f00' },
                                // '.MuiDataGrid-row': { bgcolor: '#f00' },
                            }}
                            rows={rowsMN}
                            // columns={props.columnsMN}
                            columns={[...columnsDymamic, AuthService.hasPermission(constants.PERMISSION_EDIT) ? actionsCol : {}]}
                            // columns={columnsDymamic}
                            editMode="row"
                            rowHeight={32}
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                            processRowUpdate={processRowUpdate}
                            disableRowSelectionOnClick
                            slots={{
                                toolbar: EditToolbar,
                            }}
                            slotProps={{
                                toolbar: { setRows: setRowsMN, setRowModesModel },
                            }}
                            experimentalFeatures={{ newEditingApi: true }}
                        />
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditMN}>Cancel</Button>
                    {AuthService.hasPermission(constants.PERMISSION_EDIT) ?
                        <Button onClick={handleCloseSaveMN}>Save</Button>
                        : ""}
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDialogUpload}
                onClose={handleCloseUpload}
                fullWidth={true}
                maxWidth="xs"
                disableEscapeKeyDown
            >
                <DialogTitle>Upload data for {props.rowToEdit?.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>

                    <Button
                        component="label"
                        variant="outlined"
                        startIcon={<UploadFileIcon />}
                        sx={{ marginRight: "1rem" }}
                    >
                        Select Excel
                        <input type="file" accept=".xlsx" hidden onChange={handleFileInputChange} />
                    </Button>
                    {selectedFile?.name}
                    <br />
                    <FormControlLabel
                        sx={{ m: 1, width: 200 }}
                        control={<Checkbox
                            onChange={(event) => {
                                setReplaceData(event.target.checked);
                            }}
                            checked={replaceData}
                        />}
                        label="Replace data" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUpload}>Cancel</Button>
                    <Button onClick={handleSaveUpload}>Upload</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default RuleMNDialog;
import UserService from "../services/user.service";

import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';

import * as constants from '../common/constants';
import { removeParamFromUrl } from "../common/functions";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import { useSnackbar } from 'notistack';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}



function UploadLogsFilter(props) {
    const { enqueueSnackbar } = useSnackbar();

    // available options for filters
    const [filterProjects, setFilterProjects] = React.useState([]);
    const [filterBatchNumber, setFilterBatchNumber] = React.useState([]);
    const [filterLogEnv, setFilterLogEnv] = React.useState([]);
    const [filterLogCono, setFilterLogCono] = React.useState([]);
    const [filterLogStatus, setFilterLogStatus] = React.useState([]);

    // selected filter options
    const [selProjects, setSelProjects] = React.useState([]);
    const [selBatchNumber, setSelBatchNumber] = React.useState([]);
    const [selLogEnv, setSelLogEnv] = React.useState([]);
    const [selLogCono, setSelLogCono] = React.useState([]);
    const [selLogStatus, setSelLogStatus] = React.useState([]);

    const [showIdRuleMessage, setShowIdRuleMessage] = React.useState(false);

    const filterStatuses = constants.STATUSES;

    React.useEffect(() => {

        // get params from URL and update the ones to be pre-selected
        let url_string = window.location.href;
        let url = new URL(url_string);

        let project = url.searchParams.get("project");
        if (project) {
            localStorage.setItem("filterProjects", project);
        }
        let batchNumber = url.searchParams.get("batchNumber");
        if (batchNumber) {
            localStorage.setItem("filterBatchNumber", batchNumber);
        }
        let env = url.searchParams.get("env");
        if (env) {
            localStorage.setItem("filterLogEnv", env);
        }
        let cono = url.searchParams.get("cono");
        if (cono) {
            localStorage.setItem("filterLogCono", cono);
        }
        let logStatus = url.searchParams.get("logStatus");
        if (logStatus) {
            localStorage.setItem("filterLogStatus", logStatus);
        }

        let tmp;
        tmp = JSON.parse(localStorage.getItem('filterProjects'));
        setSelProjects(tmp == null ? [] : tmp);
        tmp = JSON.parse(localStorage.getItem('filterBatchNumber'));
        setSelBatchNumber(tmp == null ? [] : tmp);
        tmp = JSON.parse(localStorage.getItem('filterLogEnv'));
        setSelLogEnv(tmp == null ? [] : tmp);
        tmp = JSON.parse(localStorage.getItem('filterLogCono'));
        setSelLogCono(tmp == null ? [] : tmp);
        tmp = JSON.parse(localStorage.getItem('filterLogStatus'));
        setSelLogStatus(tmp == null ? [] : tmp);

        UserService.getLogFilterOptions({}).then(
            response => {
                setFilterProjects(response.data.projects.sort());
                setFilterBatchNumber(response.data.batchNumbers.sort());
                setFilterLogEnv(response.data.envs.sort());
                setFilterLogCono(response.data.conos.sort());
                //run update filter to make sure only relevant tables & layouts are available
                // updateFilterOptions();
            },
            error => {

            }
        );
        updateUrlBasedOnFilter();
        let filterOptions = buildFilter(false, false, false, true);
        //send the filter to parent
        if (filterOptions) {
            props.handleFilter(filterOptions);
        }
    }, []);


    const theme = useTheme();

    const updateFilterOptions = () => {
        let filterOptions = buildFilter(true, true, true);
        UserService.getFilterOptions(filterOptions).then(
            response => {
                //clear selection for table/layout
                localStorage.removeItem('filterLogEnv');
                localStorage.removeItem('filterLogCono');
                localStorage.removeItem('filterLogStatus');

                setSelLogEnv([]);
                setSelLogCono([]);
                setSelLogStatus([]);


            },
            error => {

            }
        );
    };

    const buildFilter = (excludeStatus = false, excludeLayout = false, excludeTable = false, hideWarning = false) => {
        let filterOptions = {}

        let andArray = [];
        let tmp = {};

        // if we get idRule in URL, run filter only on that field
        let url = new URL(window.location.href);
        let idRule = url.searchParams.get("idRule");
        if (idRule) {
            setShowIdRuleMessage(true);
            filterOptions = {
                "or": [
                    {
                        "and": [
                            {
                                "field": "idRule",
                                "expressionType": "=",
                                "value": idRule
                            }
                        ]
                    }
                ]
            }
            return filterOptions;
        }

        let selProjects = JSON.parse(localStorage.getItem('filterProjects'));
        if (selProjects !== undefined && selProjects !== null && selProjects.length > 0) {
            tmp = {
                "field": "project",
                "expressionType": "in",
                "values": selProjects
            }
            andArray.push(tmp);
        } else {
            if (hideWarning !== true) {
                enqueueSnackbar("Project is mandatory!", { variant: 'warning' });
            }
            return;
        }

        let selBatchNumber = JSON.parse(localStorage.getItem('filterBatchNumber'));
        if (selBatchNumber !== undefined && selBatchNumber !== null && selBatchNumber.length > 0) {
            tmp = {
                "field": "batchNumber",
                "expressionType": "in",
                "values": selBatchNumber
            }
            andArray.push(tmp);
        }

        let selLogEnv = JSON.parse(localStorage.getItem('filterLogEnv'));
        if (selLogEnv !== undefined && selLogEnv !== null && selLogEnv.length > 0) {
            tmp = {
                "field": "env",
                "expressionType": "in",
                "values": selLogEnv
            }
            andArray.push(tmp);
        }
        let selLogCono = JSON.parse(localStorage.getItem('filterLogCono'));
        if (selLogCono !== undefined && selLogCono !== null && selLogCono.length > 0) {
            tmp = {
                "field": "cono",
                "expressionType": "in",
                "values": selLogCono
            }
            andArray.push(tmp);
        }

        let selLogStatus = JSON.parse(localStorage.getItem('filterLogStatus'));
        if (selLogStatus !== undefined && selLogStatus !== null && selLogStatus.length > 0) {
            tmp = {
                "field": "status",
                "expressionType": "in",
                "values": selLogStatus
            }
            andArray.push(tmp);
        }

        if (andArray.length > 0) {
            filterOptions = {
                "or": [
                    {
                        "and": andArray
                    }
                ]
            }
        }
        return filterOptions;
    }

    const handleChangeProject = (event) => {
        const {
            target: { value },
        } = event;
        localStorage.setItem('filterProjects', JSON.stringify(value));
        setSelProjects(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        updateFilterOptions();
    };
    const handleChangeBatchNumber = (event) => {
        const {
            target: { value },
        } = event;
        localStorage.setItem('filterBatchNumber', JSON.stringify(value));
        setSelBatchNumber(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeLogStatus = (event) => {
        const {
            target: { value },
        } = event;
        localStorage.setItem('filterLogStatus', JSON.stringify(value));
        setSelLogStatus(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const clearFilter = (event) => {
        localStorage.removeItem('filterBatchNumber');
        localStorage.removeItem('filterLogEnv');
        localStorage.removeItem('filterLogCono');
        localStorage.removeItem('filterLogStatus');

        setSelBatchNumber([]);
        setSelLogEnv([]);
        setSelLogCono([]);
        setSelLogStatus([]);

    }

    const updateUrlBasedOnFilter = () => {

        let url_string = window.location.href;
        let url = new URL(url_string);

        // remove all params and add only the selected ones
        url.searchParams.delete('project');
        url.searchParams.delete('batchNumber');
        url.searchParams.delete('logEnv');
        url.searchParams.delete('logCono');
        url.searchParams.delete('logStatus');

        let selProjects = JSON.parse(localStorage.getItem('filterProjects'));
        if (selProjects !== undefined && selProjects !== null && selProjects.length > 0) {
            url.searchParams.append('project', JSON.stringify(selProjects));
        }
        let selBatchNumber = JSON.parse(localStorage.getItem('filterBatchNumber'));
        if (selBatchNumber !== undefined && selBatchNumber !== null && selBatchNumber.length > 0) {
            url.searchParams.append('batchNumber', JSON.stringify(selBatchNumber));
        }
        let selLogEnv = JSON.parse(localStorage.getItem('filterLogEnv'));
        if (selLogEnv !== undefined && selLogEnv !== null && selLogEnv.length > 0) {
            url.searchParams.append('logEnv', JSON.stringify(selLogEnv));
        }
        let selLogCono = JSON.parse(localStorage.getItem('filterLogCono'));
        if (selLogCono !== undefined && selLogCono !== null && selLogCono.length > 0) {
            url.searchParams.append('logCono', JSON.stringify(selLogCono));
        }
        let selLogStatus = JSON.parse(localStorage.getItem('filterLogStatus'));
        if (selLogStatus !== undefined && selLogStatus !== null && selLogStatus.length > 0) {
            url.searchParams.append('logStatus', JSON.stringify(selLogStatus));
        }

        url.search = decodeURIComponent(url.search);
        window.history.replaceState(null, null, url);
    }

    const filterEvent = (event) => {
        // always remove idRule from url since this is only temporary filter
        removeParamFromUrl('idRule');
        setShowIdRuleMessage(false);
        updateUrlBasedOnFilter();

        let filterOptions = buildFilter();
        //send the filter to parent
        if (filterOptions) {
            props.handleFilter(filterOptions);
        }

    }

    const handleChangeLogCono = (event) => {
        const {
            target: { value },
        } = event;
        localStorage.setItem('filterLogCono', JSON.stringify(value));
        setSelLogCono(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeLogEnv = (event) => {
        const {
            target: { value },
        } = event;
        localStorage.setItem('filterLogEnv', JSON.stringify(value));
        setSelLogEnv(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const exportEvent = (event) => {

        let filterOptions = buildFilter();
        //send the filter to parent
        if (filterOptions) {
            props.handleExport(filterOptions);
        }

    }

    return (

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Box sx={{ display: 'flex', gap: 0, alignItems: 'center', flexWrap: 'wrap' }}>
                {showIdRuleMessage ? <Stack sx={{ width: '100%', pb: 1 }} >
                    <Alert variant="outlined" sx={{
                        height: 27, pb: 0, pt: 0,
                        "& .MuiAlert-icon": {
                            fontSize: 20,
                            paddingTop: 0.3,
                            paddingBottom: 0.3
                        },
                        "& .MuiAlert-message": {
                            paddingTop: 0.3,
                            paddingBottom: 0.3
                        }
                    }} severity="info">Showing only fields for selected rule. Press "FILTER" to display all.</Alert>
                </Stack>
                    : ""}
                <FormControl sx={{ m: 0.2, width: 200 }} size="small">
                    <InputLabel id="filterProject-label">Project</InputLabel>
                    <Select
                        labelId="filterProject-label"
                        id="filterProject"
                        multiple
                        value={selProjects}
                        onChange={handleChangeProject}
                        input={<OutlinedInput id="select-multiple-chip-project" label="Project" />}
                        MenuProps={MenuProps}
                    >
                        {filterProjects.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, selProjects, theme)}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 0.2, width: 200 }} size="small">
                    <InputLabel id="filterBatchNumber-label">Batch Number</InputLabel>
                    <Select
                        labelId="filterBatchNumber-label"
                        id="filterBatchNumber"
                        multiple
                        value={selBatchNumber}
                        onChange={handleChangeBatchNumber}
                        input={<OutlinedInput id="select-multiple-chip-batchNumber" label="Batch Number" />}
                        MenuProps={MenuProps}
                    >{filterBatchNumber.map(option => {
                        return (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        )
                    })}</Select>
                </FormControl>
                <FormControl sx={{ m: 0.2, width: 200 }} size="small">
                    <InputLabel id="filterLogEnv-label">Environment</InputLabel>
                    <Select
                        labelId="filterLogEnv-label"
                        id="filterLogEnv"
                        multiple
                        value={selLogEnv}
                        onChange={handleChangeLogEnv}
                        input={<OutlinedInput id="select-multiple-chip-logEnv" label="Environment" />}
                        MenuProps={MenuProps}
                    >{filterLogEnv.map(option => {
                        return (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        )
                    })}</Select>
                </FormControl>
                <FormControl sx={{ m: 0.2, width: 200 }} size="small">
                    <InputLabel id="filterLogCono-label">Company</InputLabel>
                    <Select
                        labelId="filterLogCono-label"
                        id="filterLogCono"
                        multiple
                        value={selLogCono}
                        onChange={handleChangeLogCono}
                        input={<OutlinedInput id="select-multiple-chip-logCono" label="Company" />}
                        MenuProps={MenuProps}
                    >{filterLogCono.map(option => {
                        return (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        )
                    })}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 0.2, width: 200 }} size="small">
                    <InputLabel id="filterLogStatus-label">Status</InputLabel>
                    <Select
                        labelId="filterLogStatus-label"
                        id="filterLogStatus"
                        multiple
                        value={selLogStatus}
                        onChange={handleChangeLogStatus}
                        input={<OutlinedInput id="select-multiple-chip-logStatus" label="Status" />}
                        MenuProps={MenuProps}
                    >{constants.LOG_STATUSES.map(option => {
                        return (
                            <MenuItem key={option.id} value={option.id}>
                                {option.val}
                            </MenuItem>
                        )
                    })}</Select>
                </FormControl>
                <Button
                    sx={{ margin: 0.2 }}
                    variant="outlined"
                    onClick={clearFilter}
                >Clear</Button>

                <Button
                    sx={{ margin: 0.2 }}
                    variant="outlined"
                    onClick={filterEvent}
                >Filter</Button>
            </Box>
            <Box sx={{
                marginTop: '0.1em'
            }}>
                {props.showExport === true ?
                    <Button
                        sx={{ margin: 0.2 }}
                        variant="outlined"
                        onClick={exportEvent}
                    >Export</Button>
                    : ""}
            </Box>
        </Box>
    )
}

export default UploadLogsFilter;
import React, { useState, useEffect } from "react";
import { DataGrid, useGridApiContext, useGridSelector, gridPageCountSelector, gridPageSelector, GridToolbarQuickFilter } from "@mui/x-data-grid";
import UserService from "../services/user.service";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem, FormControl, InputLabel, Box, Pagination } from '@mui/material';

import DialogContentText from '@mui/material/DialogContentText';
import OutlinedInput from '@mui/material/OutlinedInput';

import IconButton from '@mui/material/IconButton';
import { GrEdit, GrHistory } from 'react-icons/gr'

import UploadLogsFilter from "./uploadLogsFilter.component";
import { RenderCellExpand, GridCellExpand } from "../common/renderCellExpand";

import { useSnackbar } from 'notistack';

import AuthService from "../services/auth.service";
import * as constants from "../common/constants";

function M3UploadLogs() {
    const { enqueueSnackbar } = useSnackbar();
    const [selectedRowIDs, setSelectedRowIDs] = useState([]);
    const [logs, setLogs] = useState([]);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [currentLog, setCurrentLog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openDialogEditMultiple, setOpenDialogEditMultiple] = React.useState(false);
    const [logStatusEdit, setLogStatusEdit] = React.useState("");

    useEffect(() => {
        loadLogs();
    }, []);

    const loadLogs = () => {
        let tmpFilter = JSON.parse(localStorage.getItem('lastFilterUploadLogs'));
        UserService.getM3UploadLogs(tmpFilter)
            .then(response => {
                setLogs(response.data);
                setSelectedRowIDs([]);
                setLoading(false);
            })
            .catch(error => {
                setError("Failed to load logs");
                setLoading(false);
                console.error("Error loading logs:", error);
            });
    };

    const handleEditClick = (log) => {
        setCurrentLog(log);
        setEditDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setEditDialogOpen(false);
        setCurrentLog(null);
    };

    const handleSave = () => {
        const id = currentLog.id;
        const updatedLog = { ...currentLog };
        delete updatedLog.id;

        UserService.updateM3UploadLog(id, updatedLog)
            .then(() => {
                handleCloseDialog();
                loadLogs();
            })
            .catch(error => {
                console.error("Error updating log:", error);
            });
    };

    const handleFieldChange = (field, value) => {
        setCurrentLog(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const CustomFooterStatusComponent = () => {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <Box sx={{ p: 1, display: 'flex', borderTop: 1, borderColor: '#ddd' }}>
                <Box sx={{ m: 0, flex: 1 }}>
                    {selectedRowIDs.length > 0 && AuthService.hasPermission(constants.PERMISSION_EDIT) &&
                        <Button
                            sx={{ m: 0 }}
                            variant="outlined"
                            onClick={() => setOpenDialogEditMultiple(true)}
                        >
                            Set status for {selectedRowIDs.length} selected rows
                        </Button>
                    }
                </Box>
                <Box>
                    <Pagination
                        sx={{ height: 37 }}
                        color="primary"
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) => apiRef.current.setPage(value - 1)}
                    />
                </Box>
            </Box>
        );
    };

    const handleLogStatusCloseEdit = () => {
        setOpenDialogEditMultiple(false);
    };

    const handleLogStatusSaveEdit = () => {
        setOpenDialogEditMultiple(false);

        let updateData = {
            "status": logStatusEdit,
            "ids": selectedRowIDs
        }
        UserService.updateUploadLogsMultipleStatus(updateData).then(res => {
            
            loadLogs();
            enqueueSnackbar('Successfully updated status', { variant: 'success' });

        },
            error => {
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    };

    const columns = [
        { field: 'id', headerName: 'Id', width: 100 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 70,
            renderCell: (params) => {
                const onClickEdit = (e) => {
                    e.stopPropagation(); // don't select this row after clicking


                    return handleEditClick(params.row);
                };

                // const onClickHistory = (e) => {
                //     e.stopPropagation(); // don't select this row after clicking
                //     return showHistoryDialog(params.row);
                // };

                return <span>
                    <IconButton sx={{ pt: 0.1 }} size="small" onClick={onClickEdit}><GrEdit /></IconButton>
                    {/* <IconButton sx={{ pt:0.1 }} size="small" onClick={onClickHistory}><GrHistory /></IconButton> */}
                </span>;
            }
        },
        {
            field: 'project', headerName: 'Project', width: '90',
            renderCell: (params) => (
                <GridCellExpand
                    value={params.value || ""}
                    width={params.colDef.computedWidth + 100}
                />
            )
        },
        { field: 'batchNumber', headerName: 'Batch Number', width: 120 },
        { field: 'env', headerName: 'ENV', width: 70 },
        { field: 'cono', headerName: 'CONO', width: 70 },
        {
            field: 'api', headerName: 'API', width: 250,
            renderCell: (params) => (
                <GridCellExpand
                    value={params.value || ""}
                    width={params.colDef.computedWidth + 100}
                />
            )
        },
        { field: 'errorCode', headerName: 'Error Code', width: 100 },
        {
            field: 'errorMessage', headerName: 'Error Message', width: 300,
            renderCell: (params) => (
                <GridCellExpand
                    value={params.value || ""}
                    width={params.colDef.computedWidth + 100}
                />
            )
        },
        { field: 'count', headerName: 'Count', width: 80 },
        { field: 'status', headerName: 'Status', width: 120 },
        { field: 'comment', headerName: 'Comment', width: 200 },
        { field: 'responsible', headerName: 'Responsible', width: 150 },
        { field: 'uploadTimestamp', headerName: 'Upload Time', width: 200 },
    ];

    function QuickSearchToolbar() {
        return (
            <Box
                sx={{
                    p: 0.5,
                    pb: 0,
                }}
            >
                <GridToolbarQuickFilter />
            </Box>
        );
    }

    const handleFilterUpdate = (value) => {
        localStorage.setItem('lastFilterUploadLogs', JSON.stringify(value));
        
        UserService.getM3UploadLogs(value).then(
            response => {
                setLogs(response.data);
                setLoading(false);
                
            },
            error => {
                setLogs([]);
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    };

    const handleExport = (value) => {

        // UserService.downloadRestructuringData(value).then(
        //     response => {
        //         if (response.data.restructuringDataBase64) {
        //             triggerBase64Download('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
        //                 + response.data.restructuringDataBase64, 'Restructuring_' + getCurrentTimestamp());
        //         }
        //     },
        //     error => {
        //         const resMessage =
        //             (error.response.data.errorMessage) ||
        //             error.message ||
        //             error.toString();
        //         enqueueSnackbar(resMessage, { variant: 'error' });
        //     }
        // );
    }

    return (
        <div style={{ height: 'calc(100vh - 150px)', width: '100%' }}>
            <header className="">
                <h3>M3 Upload Logs</h3>
                <UploadLogsFilter 
                    handleFilter={handleFilterUpdate} 
                    handleExport={handleExport}
                    showExport={false}/> 
            </header>
            <DataGrid
                rows={logs}
                columns={columns}
                columnVisibilityModel={{
                    id: false,
                }}
                loading={loading}
                density="compact"
                editMode="row"
                checkboxSelection
                disableRowSelectionOnClick
                rowHeight={32}
                pagination
                pageSize={100}
                pageSizeOptions={[100]}
                onRowDoubleClick={(params) => handleEditClick(params.row)}
                slots={{
                    footer: CustomFooterStatusComponent,
                    toolbar: QuickSearchToolbar
                }}
                rowSelectionModel={selectedRowIDs}
                onRowSelectionModelChange={(newSelection) => {
                    setSelectedRowIDs(newSelection);
                }}
            />
            <Dialog open={editDialogOpen} onClose={handleCloseDialog}
                fullWidth={true}
                maxWidth="md">
                <DialogTitle>Edit Log Entry<br/>
                    <small>{currentLog?.project} / {currentLog?.batchNumber} / {currentLog?.env} / {currentLog?.cono} / {currentLog?.api} / {currentLog?.errorCode}</small>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <FormControl sx={{ mt: 1, width: 257 }} >
                            <InputLabel id="statusEdit-label">Status</InputLabel>
                            <Select
                                labelId="statusEdit-label"
                                id="statudEdit"
                                value={currentLog?.status || ''}
                                onChange={(e) => handleFieldChange('status', e.target.value)}
                                input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                            >
                                {constants.LOG_STATUSES.map(option => {
                                    return (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.val}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <TextField
                            sx={{ mt: 1, width: 257 }}
                            label="Responsible"
                            value={currentLog?.responsible || ''}
                            onChange={(e) => handleFieldChange('responsible', e.target.value)}
                        />
                    </Box>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Comment"
                        multiline
                        maxRows={40}
                        minRows={5}
                        value={currentLog?.comment || ''}
                        onChange={(e) => handleFieldChange('comment', e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDialogEditMultiple}
                onClose={handleLogStatusCloseEdit}
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogTitle>Update status for selected</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>

                    <FormControl sx={{ m: 1, width: 200 }} >
                        <InputLabel id="logStatusEdit-label">Status</InputLabel>
                        <Select
                            labelId="logStatusEdit-label"
                            id="logStatusEdit"
                            value={logStatusEdit === "" ? setLogStatusEdit(constants.LOG_STATUSES[0].id) : logStatusEdit}
                            onChange={event => setLogStatusEdit(event.target.value)}
                            input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                        >
                            {constants.LOG_STATUSES.map(option => {
                                return (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.val}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLogStatusCloseEdit}>Cancel</Button>
                    {AuthService.hasPermission(constants.PERMISSION_EDIT) ?
                        <Button onClick={handleLogStatusSaveEdit}>Save</Button>
                        : ""}
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default M3UploadLogs;